import { isAfter } from "date-fns"
import { FC, ReactNode, useMemo, useRef } from "react"
import { Maybe } from "../../graphql/generated/client-types-and-hooks"
import { getDateProgressPercentageToNow } from "../../helpers/progress/getDateProgressPercentageToNow"
import { useWindowSize } from "../../hooks/useWindowSize"

type DateProgressGroupProps = {
  start?: Maybe<string | Date>
  end?: Maybe<string | Date>
  children: ReactNode
  isComplete?: boolean
}

export const DateProgressGroup: FC<DateProgressGroupProps> = ({ children, start, end, isComplete = false }) => {
  if (typeof start === "string") start = new Date(start)
  if (typeof end === "string") end = new Date(end)

  const container = useRef<HTMLDivElement | null>(null)
  const { width: windowWidth } = useWindowSize()
  const progressPercentage = getDateProgressPercentageToNow(start, end)

  const barPosition = useMemo<number>(() => {
    if (!container.current) return 0

    const { width: containerWidth } = container.current.getBoundingClientRect()
    const pinWidth = 4
    return containerWidth * (1 - progressPercentage) + pinWidth
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progressPercentage, windowWidth])

  return (
    <div className="relative text-[13px] max-w-[320px]" ref={container}>
      <div className="grid gap-1">
        {children}
        {!isComplete && progressPercentage < 1 && isAfter(new Date(), start || new Date()) && (
          <div className="absolute h-full inset-y-0" style={{ right: `${barPosition}px` }}>
            <div className="absolute w-[3px] h-full inset-y-0 left-[5px] bg-black opacity-10"></div>
            <div className="absolute w-[3px] h-full inset-y-0 left-[3px] bg-red-600"></div>
          </div>
        )}
      </div>
    </div>
  )
}
