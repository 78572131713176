import { FC } from "react"
import { ModalProps } from "../../../components/Modals/hooks/useModalProps"
import { MuiModal } from "../../../components/Modals/components/Elements/MuiModal"
import { Typography } from "@mui/material"

type Props = {
  isTaskReset?: boolean
  modalProps: ModalProps
  onResetSchedule: () => void
}

export const ResetScheduleModal: FC<Props> = ({ modalProps, onResetSchedule, isTaskReset = false }) => {
  return (
    <MuiModal
      isOpen={modalProps.isOpen}
      handleCloseModal={modalProps.handleCloseModal}
      contentLabel={`Reset ${isTaskReset ? "Task" : "Project"} Schedule?`}
      submitButtonColor="error"
      submitButtonText={`Reset ${isTaskReset ? "Task" : "Project"} Schedule?`}
      submitForm={() => {
        onResetSchedule?.()
        modalProps.handleCloseModal()
      }}
    >
      <div className="flex flex-col bg-red-50 border border-red-600 text-red-800 p-2 rounded">
        <Typography variant="h5" className="text-red-800">
          {`You're about to reset the ${isTaskReset ? "task" : "project"} schedule`}
        </Typography>
        <Typography variant="body1">If you continue the following changes will be made:</Typography>
        <div className="ml-[40px] mt-4">
          <ul className="list-disc">
            {isTaskReset ? (
              <>
                <li>Any changes to the task schedule will be permanently removed</li>
                <li>The task schedule will be reset to the project schedule</li>
              </>
            ) : (
              <>
                <li>Any changes to the project schedule will be permanently removed</li>
                <li>The project schedule will be reset to the organization schedule</li>
                <li>All tasks that inherit this project schedule will be affected</li>
                <li>Any tasks with custom schedules will NOT be affected</li>
              </>
            )}
          </ul>
        </div>
      </div>
    </MuiModal>
  )
}
