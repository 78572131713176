import { Button, Checkbox, Divider, ListItemText, MenuItem, Select, Typography } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { Dispatch, SetStateAction, useState } from "react"
import { BiFilter, BiX } from "react-icons/bi"

type TaskItem = {
  id: string
  name: string
}

export const FilterDropdownButton = <T extends TaskItem>({
  unfiltered = [],
  filtered = [],
  setFiltered,
  filterBy,
}: {
  unfiltered: T[]
  filtered: string[]
  setFiltered: Dispatch<SetStateAction<string[]>>
  filterBy: (item: T) => string
}) => {
  const [open, setOpen] = useState(false)
  const theme = useTheme()
  const chosenFilter = unfiltered.filter((task) => !filtered.includes(task.id))

  return (
    <>
      <Button
        className="px-4"
        id="taskFilterButton"
        variant="contained"
        color="secondary"
        startIcon={<BiFilter />}
        onClick={() => setOpen(true)}
        sx={{ borderRadius: `${theme.spacing(3)}` }}
      >
        Filter{" "}
        {filtered.length === unfiltered.length ? (
          <Typography color="secondary.light" sx={{ marginLeft: theme.spacing(0.5) }}>
            None
          </Typography>
        ) : filtered.length === unfiltered.length - 1 ? (
          <Typography color="secondary.light" sx={{ marginLeft: theme.spacing(0.5) }}>
            {`${chosenFilter[0].name}`}
          </Typography>
        ) : (
          <Typography color="secondary.light" sx={{ marginLeft: theme.spacing(0.5) }}>
            {`${unfiltered.length - filtered.length} tasks`}
          </Typography>
        )}
      </Button>
      {filtered.length !== unfiltered.length && (
        <Button
          className="px-4"
          id="taskFilterButton"
          variant="contained"
          color="secondary"
          startIcon={<BiX />}
          sx={{ borderRadius: `${theme.spacing(4)}`, marginLeft: `${theme.spacing(0.5)}` }}
          onClick={() => setFiltered([...unfiltered.map(filterBy)])}
        >
          Remove
        </Button>
      )}
      <Select
        multiple
        hidden
        value={filtered}
        onChange={(e) => {
          if (Array.isArray(e.target.value)) {
            setFiltered(e.target.value)
          }
        }}
        open={open}
        onClose={() => setOpen(false)}
        MenuProps={{
          BackdropProps: { sx: { background: "transparent" } },
          anchorEl: document.getElementById("taskFilterButton"),
          style: { marginLeft: "80px" },
        }}
      >
        <Divider />
        {unfiltered.map(({ id, name }) => (
          <MenuItem key={id} value={id} sx={{ "&.Mui-selected": { backgroundColor: "transparent" } }}>
            <Checkbox checked={!filtered.includes(id)} />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
    </>
  )
}
