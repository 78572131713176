import { useContext, useEffect, useState } from "react"
import {
  Schedule,
  ScheduledBreak,
  Task,
  useDeleteCustomProjectScheduleMutation,
  useDeleteCustomTaskScheduleMutation,
} from "../../../graphql/generated/client-types-and-hooks"
import { AddOrEditScheduleDrawer } from "../../../components/Partials/Drawer/components/AddOrEditScheduleDrawer"
import { DrawerContext } from "../../../components/Partials/Drawer/providers/DrawerProvider"
import { ScheduleBreakCreateType } from "../../../pages/projects/create/_index"
import { PickPlus } from "../../../types/helpers"

type ScheduledBreakType = PickPlus<
  ScheduledBreak,
  "id" | "durationInMinutes" | "isActive" | "localizedStartTime" | "name" | "breakTaskId"
> & {
  breakTask: PickPlus<Task, "id" | "name" | "isUnpaid" | "projectId">
}

const useInheritedSchedule = (
  parentSchedule: Schedule | null | undefined,
  parentBreaks: ScheduledBreakType[] | null | undefined,
  projectId: string,
  schedule: Schedule | null | undefined,
  scheduledBreaks: ScheduledBreakType[] | null | undefined,
  scheduleType: "project" | "task" = "project"
) => {
  const [, deleteProjectSchedule] = useDeleteCustomProjectScheduleMutation()
  const [, deleteTaskSchedule] = useDeleteCustomTaskScheduleMutation()

  const { push: pushDrawer } = useContext(DrawerContext)

  const parentScheduleId = parentSchedule?.id
  const [isCustomizingFromOrgSchedule, setIsCustomizingFromOrgSchedule] = useState<boolean>(false)
  const [hasEditedSchedule, setHasEditedSchedule] = useState(false)
  const [isInheritedSchedule, setIsInheritedSchedule] = useState(false)
  const [currentSchedule, setCurrentSchedule] = useState<Schedule | undefined>(schedule ?? undefined)
  const [currentScheduledBreaks, setCurrentScheduledBreaks] = useState<ScheduledBreakType[] | undefined>(
    scheduledBreaks ?? []
  )

  useEffect(() => {
    const hasInheritedSchedule =
      (!currentSchedule && parentScheduleId && !hasEditedSchedule) ||
      (!currentSchedule?.id && !parentScheduleId && !hasEditedSchedule) ||
      currentSchedule?.id === parentScheduleId

    setIsInheritedSchedule(hasInheritedSchedule)
  }, [currentSchedule, parentScheduleId, hasEditedSchedule])

  useEffect(() => {
    if (hasEditedSchedule) return

    if (!schedule) {
      setCurrentSchedule(parentSchedule ?? undefined)
      setCurrentScheduledBreaks(parentBreaks ?? [])
    } else {
      setCurrentSchedule(schedule ?? undefined)
      setCurrentScheduledBreaks(scheduledBreaks ?? [])
    }
  }, [hasEditedSchedule, parentBreaks, parentSchedule, schedule, scheduledBreaks])

  const onSaveSchedule = (scheduleToSave: Schedule, updatedScheduledBreaks: ScheduleBreakCreateType[]) => {
    const createNewSchedule = isInheritedSchedule
    const newSchedule = { ...scheduleToSave, id: createNewSchedule ? "" : scheduleToSave.id }

    setCurrentSchedule(newSchedule)

    const scheduledBreaksToSave = updatedScheduledBreaks
      .filter((sb) => (createNewSchedule ? sb.isActive : true))
      ?.map((scheduledBreak) => {
        const breakTask =
          scheduledBreak?.breakTask ?? currentScheduledBreaks?.find((sb) => sb.id === scheduledBreak.id)?.breakTask
        return {
          ...scheduledBreak,
          breakTask: breakTask ?? { name: "", id: "", projectId, isUnpaid: false },
          id: createNewSchedule ? "" : scheduledBreak.id,
        }
      })

    setCurrentScheduledBreaks(scheduledBreaksToSave)
    setHasEditedSchedule(true)
  }

  const onEditSchedule = () => {
    const isEditingOrgSchedule = !!currentSchedule?.id && isInheritedSchedule
    const id = isEditingOrgSchedule || !currentSchedule?.id ? "" : currentSchedule.id

    pushDrawer(
      <AddOrEditScheduleDrawer
        handleSave={onSaveSchedule}
        schedule={currentSchedule ? { ...(currentSchedule || undefined), id } : undefined}
        scheduledBreaks={currentScheduledBreaks?.map((sb) => ({ ...sb, id: isEditingOrgSchedule ? "" : sb.id })) || []}
        projectId={projectId}
      />,
      "AddOrEditSchedule"
    )
    setIsCustomizingFromOrgSchedule(isEditingOrgSchedule)
  }

  const resetSchedule = () => {
    setCurrentSchedule(parentSchedule ?? undefined)
    setCurrentScheduledBreaks(parentBreaks ?? [])
  }

  const getScheduleAndBreaksData = async () => {
    let updatedSchedule: Schedule | undefined = { ...currentSchedule, id: currentSchedule?.id ?? "" }
    let updatedBreaks = currentScheduledBreaks
      ?.filter((sb) => (isCustomizingFromOrgSchedule ? sb.isActive : true))
      ?.map(({ breakTask, ...scheduledBreak }) => {
        return {
          ...scheduledBreak,
          isUnpaid: breakTask?.isUnpaid ?? true,
        }
      })

    if (isInheritedSchedule) {
      updatedSchedule = undefined
      updatedBreaks = undefined

      // delete existing project schedule
      if (!!schedule?.id && schedule?.id !== parentSchedule?.id) {
        scheduleType === "task"
          ? await deleteTaskSchedule({ scheduleId: schedule?.id ?? "" })
          : await deleteProjectSchedule({ projectId, scheduleId: schedule?.id ?? "" })
      }
    }

    return { schedule: updatedSchedule, scheduledBreaks: updatedBreaks }
  }

  return {
    hasEditedSchedule,
    isInheritedSchedule,
    currentSchedule,
    currentScheduledBreaks,
    onEditSchedule,
    resetSchedule,
    getScheduleAndBreaksData,
  }
}

export default useInheritedSchedule
