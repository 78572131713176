import { LocationWithRadius } from "../../../graphql/generated/client-types-and-hooks"
import { LatLongLiteral } from "../../../types/Maps"

// useful for development
export const generateRandomLocations = (position: LatLongLiteral) => {
  const locations: Array<LatLongLiteral> = []

  for (let i = 0; i < 100; i++) {
    const direction = Math.random() < 0.5 ? -2 : 2
    locations.push({
      lat: position.lat + Math.random() / direction,
      lng: position.lng + Math.random() / direction,
    })
  }
  return locations
}

export const CENTER_OF_US: LatLongLiteral = { lat: 39.8283, lng: -98.5795 }

export const METERS_PER_MILE = 1609.34

export const getDirectionsMapUrl = (
  location: LocationWithRadius | LatLongLiteral,
  useDeviceUrls = true,
  ignoreMacOs = true
) => {
  if (location?.lat == null || location?.lng == null) {
    return ""
  }

  const stringsToMatch = `iPad|iPhone|iPod${!ignoreMacOs ? "|Macintosh" : ""}`
  const matcher = new RegExp(stringsToMatch, "g")

  const isAppleDevice = matcher.test(navigator.userAgent)

  const { lat, lng } = location

  const appleMapsUrl = `https://maps.apple.com/?q=${lat},${lng}&dirflg=d&t=h`
  const googleMapsUrl = `https://google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${lat},${lng}`

  if (useDeviceUrls) {
    return isAppleDevice ? appleMapsUrl : googleMapsUrl
  }

  return googleMapsUrl
}
