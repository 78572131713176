import { useEffect, useRef } from "react"
import { calculateScroll } from "../helpers/numbers/calculateScroll"
import { useWindowSize } from "./useWindowSize"

export const usePinElements = <T, U>() => {
  const size = useWindowSize()
  const targetRef = useRef<T & HTMLElement>(null)
  const pinnedRef = useRef<U & HTMLElement>(null)

  useEffect(() => {
    if (targetRef.current && pinnedRef.current && size.height) {
      const [scrollY] = calculateScroll(pinnedRef.current)
      const boundingClientRect = targetRef.current.getBoundingClientRect()

      pinnedRef.current.style.top = `${boundingClientRect.bottom + scrollY}px`
      pinnedRef.current.style.maxHeight = `${size.height - boundingClientRect.top - 10}px`
      pinnedRef.current.style.left = `${boundingClientRect.left}px`
      pinnedRef.current.style.width = `${boundingClientRect.width}px`
    }
  })

  return [targetRef, pinnedRef]
}
