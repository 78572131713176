import { Task, TaskListItem } from "../../graphql/generated/client-types-and-hooks"

export type ProgressSummary = {
  noHours: boolean | undefined
  targetHours: number
  completedHours: number
  targetUnits: number
  completedUnits: number
  completionPercentage: number
}

export type TaskProgressDataType = {
  id: Task["id"] | null | undefined
  isComplete?: Task["isComplete"]
  isDefault?: Task["isDefault"]
  estimatedHours?: number | null | undefined
  timeEntriesSumDurationInSeconds: number | null | undefined
  unitGoalsSumProgress: number | null | undefined
  unitGoalsSumTargetQuantity: number | null | undefined
}

const defaultTotals = {
  targetHours: 0,
  completedHours: 0,
  targetUnits: 0,
  completedUnits: 0,
}

export const getProjectProgressSummary = (tasks: TaskProgressDataType[] | undefined): ProgressSummary => {
  const taskResults = tasks
    ?.filter((task) => !task.isDefault)
    .map((task) => ({
      isComplete: task.isComplete,
      isDefault: task.isDefault,
      estimatedHours: task.estimatedHours,
      completedHours: task?.timeEntriesSumDurationInSeconds
        ? +(task.timeEntriesSumDurationInSeconds / 3600).toFixed(2)
        : 0,
      unitProgress: task.unitGoalsSumProgress,
      unitTargetGoal: task.unitGoalsSumTargetQuantity,
    })) as unknown as TaskListData[]
  const results = getNewTaskGroupProgress(taskResults)

  return {
    ...results,
    completionPercentage: results.completionPercentage * 0.01,
  }
}

type TaskListData = Pick<
  TaskListItem,
  "isComplete" | "isDefault" | "estimatedHours" | "completedHours" | "unitTargetGoal" | "unitProgress"
>

export const getNewTaskGroupProgress = (tasks: TaskListData[] | null | undefined): ProgressSummary => {
  if (!tasks || tasks.length === 0) {
    return {
      ...defaultTotals,
      noHours: true,
      completionPercentage: 0,
    }
  }

  const validTasks = tasks?.filter((task) => task.estimatedHours && task.unitTargetGoal && !task.isDefault) || []
  const validTasksForCompletionPercentage = validTasks
    .map((task) => ({
      estimatedHours: task.estimatedHours as number,
      unitProgress: task.unitProgress,
      unitTargetGoal: task.unitTargetGoal,
    }))

  const totalEstimatedHours = validTasks.reduce((sum, task) => sum + (task.estimatedHours || 0), 0)

  const adjustedCompletionPercentage = getCompletionPercentage(validTasksForCompletionPercentage, totalEstimatedHours)

  const progressSummary = tasks?.reduce(
    (acc, task) => {
      if (task.isDefault) return acc

      return {
        completedHours: acc.completedHours + (task.completedHours || 0),
        targetUnits: acc.targetUnits + (task.unitTargetGoal || 0),
        completedUnits: acc.completedUnits + (task.unitProgress || 0),
      }
    },
    { completedHours: 0, targetUnits: 0, completedUnits: 0 }
  )

  return {
    targetHours: totalEstimatedHours,
    completedHours: progressSummary.completedHours,
    targetUnits: progressSummary.targetUnits,
    completedUnits: progressSummary.completedUnits,
    completionPercentage: adjustedCompletionPercentage,
    noHours: totalEstimatedHours === 0,
  }
}

type TasksForCompletionPercentage = {
  estimatedHours: number | null | undefined
  unitProgress: number | null | undefined
  unitTargetGoal: number | null | undefined
}

export const getCompletionPercentage = (tasks: TasksForCompletionPercentage[], totalEstimatedHours: number) => {
  let adjustedCompletionPercentage = 0

  tasks.forEach((task) => {
    const taskWeight = (task.estimatedHours || 0) / totalEstimatedHours
    const taskCompletion = (task.unitProgress || 0) / (task.unitTargetGoal || 1)

    if (taskCompletion < 1) {
      adjustedCompletionPercentage += taskCompletion * taskWeight
    } else {
      adjustedCompletionPercentage += taskWeight
    }
  })
  adjustedCompletionPercentage = Math.round(adjustedCompletionPercentage * 1000) / 10

  return adjustedCompletionPercentage
}