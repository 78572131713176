import React, { FC, ReactNode } from "react"

type Props = {
  children: ReactNode
  className?: string
}

export const MultiSelectTable: FC<Props> = ({ children, className = "" }) => {
  return (
    <>
      {children ? (
        <div className={className}>{children}</div>
      ) : (
        <div className="px-3 py-2 text-gray-400">No matches</div>
      )}
    </>
  )
}
