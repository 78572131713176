import { Autocomplete, CircularProgress, FilterOptionsState, TextField } from "@mui/material"
import { useField } from "formik"
import { HTMLAttributes, ReactNode } from "react"

interface BaseOption {
  id: string
}

type AutoCompleteWithSearchProps<T extends BaseOption> = {
  disabled?: boolean
  fetching?: boolean
  filterOptions?: (options: T[], params: FilterOptionsState<T>) => T[]
  label: string
  placeholder: string
  getOptionLabel: (option: T) => string
  name: string
  options: T[]
  renderOption: (props: HTMLAttributes<HTMLLIElement>, option: T) => ReactNode
}

export const AutoCompleteWithSearch = <T extends BaseOption>({
  disabled = false,
  fetching = false,
  filterOptions,
  getOptionLabel,
  label,
  name,
  placeholder,
  options,
  renderOption,
}: AutoCompleteWithSearchProps<T>) => {
  const [field, { touched, error }, { setValue }] = useField(name)

  const errorShouldBeDisplayed = touched && !!error && !disabled

  const selectedOption = options.find((option) => option.id === field.value) || null

  return (
    <Autocomplete
      className="grow"
      disabled={fetching}
      filterOptions={filterOptions}
      getOptionLabel={getOptionLabel}
      onChange={(_e, value) => setValue(value ? value.id : null)}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          value={selectedOption}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {fetching ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          label={label}
          placeholder={placeholder}
          error={errorShouldBeDisplayed}
          helperText={error}
        />
      )}
      renderOption={renderOption}
      value={selectedOption}
    />
  )
}
