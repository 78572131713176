import { clsx } from "clsx"
import { FC, MouseEvent } from "react"
import { BiX } from "react-icons/bi"
import { MdArrowDropDown } from "react-icons/md"
import { pluralize } from "../../../helpers/strings/pluralize"
import { MultiSelectOption } from "./MultiSelect"

type Props = {
  error?: boolean
  label: string
  value: string
  disabled?: boolean
  placeholder?: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onClear: (event: MouseEvent<SVGElement>) => void
  modalIsOpen: boolean
  selectedItems: MultiSelectOption[]
  isSingleSelect?: boolean
  selectedTemplate?: (items: MultiSelectOption[]) => JSX.Element
}

type DefaultSelectedTemplateProps = {
  error?: boolean
  isSingleSelect?: boolean
  label: string
  placeholder?: string
  selectedItems: MultiSelectOption[]
}

const DefaultSelectedTemplate: FC<DefaultSelectedTemplateProps> = ({
  error = false,
  label,
  selectedItems,
  isSingleSelect,
  placeholder,
}) => {
  const count = selectedItems.length

  if (count === 0) {
    return <span className={clsx(error ? "text-red-600" : "text-gray-400")}>{placeholder ?? `Select ${label}`}</span>
  }

  if (isSingleSelect && count === 1) {
    return <span>{selectedItems.at(0)?.label}</span>
  }

  return (
    <span>
      ({count}) {pluralize(label, count)} selected
    </span>
  )
}

export const MultiSelectInputBar: FC<Props> = ({
  error = false,
  isSingleSelect = false,
  label,
  value: searchText,
  disabled = false,
  placeholder,
  onChange,
  onClear,
  selectedTemplate,
  modalIsOpen,
  selectedItems,
}) => (
  <div className={clsx("grow", disabled && "opacity-40")}>
    <div className="flex justify-between items-center h-10 p-2 grow">
      <div className="flex gap-x-3 justify-between items-center h-10 p-2 pr-0 grow">
        {disabled || !modalIsOpen ? (
          selectedTemplate ? (
            selectedTemplate(selectedItems)
          ) : (
            <DefaultSelectedTemplate
              error={error}
              placeholder={placeholder}
              label={label}
              selectedItems={selectedItems}
              isSingleSelect={isSingleSelect}
            />
          )
        ) : (
          <input
            autoFocus
            onChange={onChange}
            value={searchText}
            placeholder={placeholder || `Select ${label}`}
            className="placeholder:text-gray-500 flex-1 border-none focus:outline-none w-full"
          />
        )}
        {!disabled && modalIsOpen && (searchText || selectedItems.length > 0) && (
          <button type="button">
            <BiX onClick={onClear} className="size-5 text-gray-400" />
          </button>
        )}
      </div>
      <MdArrowDropDown className={clsx("size-6 text-gray-500", modalIsOpen && "-rotate-180")} />
    </div>
  </div>
)

MultiSelectInputBar.displayName = "MultiSelectInputBar"
