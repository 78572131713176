import { Form, Formik } from "formik"
import * as Yup from "yup"
import { MetadataNote, MetadataNoteInput } from "../../../graphql/generated/client-types-and-hooks"
import { ModalProps } from "../hooks/useModalProps"
import { StandardTextarea } from "../../Formik/StandardTextarea"
import { TextField } from "../../Formik/TextField"
import { MuiModal } from "./Elements/MuiModal"

type AddNoteModalValues = {
  label: string
  content: string
}

const validationSchema = Yup.object().shape({
  label: Yup.string().required("Required"),
  content: Yup.string().required("Required"),
})

export const AddNoteModal: React.FC<{
  modalProps: ModalProps
  parentName: string
  note?: MetadataNote | MetadataNoteInput
  onSave: (values: AddNoteModalValues) => void
}> = ({ modalProps, parentName, note, onSave }) => {
  return (
    <Formik<AddNoteModalValues>
      enableReinitialize
      initialValues={{ label: note?.label || "", content: note?.content || "" }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        onSave(values)
        modalProps.handleCloseModal()
      }}
    >
      {({ values, resetForm, handleSubmit, isSubmitting }) => {
        return (
          <MuiModal
            contentLabel={`Add note to ${parentName}`}
            submitButtonText="Save"
            variant="small"
            isOpen={modalProps.isOpen}
            disabled={isSubmitting}
            handleCloseModal={() => {
              resetForm()
              modalProps.handleCloseModal()
            }}
            submitForm={handleSubmit}
          >
            <Form className="flex flex-col">
              <TextField name="label" label="Title" value={values.label} />
              <StandardTextarea name="content" placeholder="Description" minRows={4} value={values.content} />
            </Form>
          </MuiModal>
        )
      }}
    </Formik>
  )
}
