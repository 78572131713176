import { IconButton, InputAdornment, TextField } from "@mui/material"
import debounce from "lodash.debounce"
import { FC, useCallback, useState } from "react"
import { BiX } from "react-icons/bi"

export const SearchBar: FC<{
  onChange: (value: string) => void
  onCancel?: (value: null) => void
  fullWidth?: boolean
}> = ({ onChange, onCancel = () => null, fullWidth = false }) => {
  const [localText, setLocalText] = useState("")
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDebounce = useCallback(debounce(onChange, 500), [])

  return (
    <TextField
      fullWidth={fullWidth}
      variant="outlined"
      value={localText}
      onChange={(e) => {
        setLocalText(e.target.value)
        handleDebounce(e.target.value)
      }}
      placeholder="Search"
      InputProps={{
        className: "h-10 pr-1",
        endAdornment: (
          <InputAdornment position="end">
            {localText.length > 0 ? (
              <IconButton
                size="medium"
                onClick={() => {
                  setLocalText("")
                  onCancel(null)
                }}
              >
                <BiX />
              </IconButton>
            ) : (
              <div className="size-10" />
            )}
          </InputAdornment>
        ),
      }}
      inputProps={{ style: { boxShadow: "none" } }}
    />
  )
}
