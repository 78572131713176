import { Form, Formik } from "formik"
import { FC, useContext, useEffect } from "react"
import { useQuery } from "urql"
import * as Yup from "yup"
import { useReassignUsersMutation } from "../../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../../graphql/generated/gql"
import { Task } from "../../../../graphql/generated/gql/graphql"
import { useHandleError } from "../../../../hooks/useHandleError"
import { PickPlus } from "../../../../types/helpers"
import { DropDownMUI, DropDownMUIItem } from "../../../DropDownMUI"
import { ButtonFilled, ButtonHollow } from "../../../deprecated"
import { UserSelect } from "../../../Formik/UserSelect"
import { ModalBody } from "../../../Modals/components/Elements/ModalBody"
import { ModalFooter } from "../../../Modals/components/Elements/ModalFooter"
import { errorSnack } from "../../../Notistack/ThemedSnackbars"
import { ModalLoadingContext } from "../../../deprecated/StandardModal"

type MyTaskExpectation = PickPlus<Task, "id" | "name" | "isComplete" | "isDefault">

type Props = {
  projectId: string
  task?: MyTaskExpectation & {
    project: PickPlus<Task["project"], "isArchived">
  }
  onSuccess: () => void
  onCancel: () => void
}

type Values = {
  users: string[]
  task: string
}
type FormContentsProps = {
  isSubmitting: boolean
  setFieldValue: (field: string, value: unknown, shouldValidate?: boolean | undefined) => void
  onCancel: () => void
  task?: MyTaskExpectation & {
    project: PickPlus<Task["project"], "isArchived">
  }
  tasks: (MyTaskExpectation & {
    project: PickPlus<Task["project"], "isArchived">
  })[]
}

const FormContents: FC<FormContentsProps> = ({ isSubmitting, tasks, onCancel }) => {
  const { setIsLoading } = useContext(ModalLoadingContext)
  useEffect(() => {
    setIsLoading(isSubmitting)
  }, [isSubmitting, setIsLoading])

  const taskMenuItems: DropDownMUIItem[] = tasks
    .filter((task) => !task.isComplete && !task.project.isArchived)
    .map((task) => ({ value: task.id, label: task.name, isDefault: task.isDefault }))

  return (
    <Form className="h-full flex flex-col">
      <ModalBody className="flex flex-col gap-8">
        <UserSelect multiple name="users" label="Team Member" />
        {tasks && <DropDownMUI items={taskMenuItems} fieldName="task" label="Assign to task" />}
      </ModalBody>
      <ModalFooter>
        <ButtonHollow onClick={onCancel} type="button">
          Cancel
        </ButtonHollow>
        <ButtonFilled type="submit" disabled={isSubmitting}>
          Add users
        </ButtonFilled>
      </ModalFooter>
    </Form>
  )
}

const AssignUserFormDocument = graphql(`
  query AssignUserForm($projectId: String!) {
    project(id: $projectId) {
      id
      isArchived
      tasks {
        id
        isComplete
        isDefault
        name
      }
    }
  }
`)

export const AssignUserForm: FC<Props> = ({ projectId, task, onSuccess, onCancel }) => {
  const { setIsLoading } = useContext(ModalLoadingContext)

  const [{ data, error }] = useQuery({
    query: AssignUserFormDocument,
    variables: { projectId },
  })

  const tasks = data?.project?.tasks || []

  const [{ error: reassignUsersMutationError }, reassignUsersMutation] = useReassignUsersMutation()
  const initialValues: Values = {
    users: [],
    task: task?.id || "",
  }
  useHandleError(reassignUsersMutationError, "Failed to assign user to project")
  useHandleError(error, "Failed to fetch data")

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        users: Yup.array().required().min(1, "At least one user must be selected."),
        task: Yup.string().trim().required("A task is required."),
      })}
      onSubmit={(values) => {
        const { users, task: taskReassignment } = values
        const assignments = users.map((user) => ({ userId: user, taskId: taskReassignment, projectId }))

        reassignUsersMutation({ assignments }).then((result) => {
          if (result.error) {
            errorSnack("An error was encountered while reassigning users. Please try again.")
            setIsLoading(false)
          } else {
            onSuccess()
          }
        })
      }}
    >
      {({ isSubmitting, setFieldValue }) => (
        <FormContents
          isSubmitting={isSubmitting}
          setFieldValue={setFieldValue}
          tasks={tasks.filter((t) => !t.isComplete).map((t) => ({ project: data?.project ?? {}, ...t }))}
          task={task}
          onCancel={onCancel}
        />
      )}
    </Formik>
  )
}
