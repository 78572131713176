import { clsx } from "clsx"
import { FC } from "react"
import { BiHash } from "react-icons/bi"
import { Organization, Project } from "../graphql/generated/client-types-and-hooks"
import { PickPlus } from "../types/helpers"
import { ProjectImage } from "./ProjectImage"

type Props = {
  organization?: PickPlus<Organization, "id" | "imageUrl" | "name">
  project: PickPlus<Project, "id" | "code" | "isArchived" | "isComplete" | "imageUrl" | "name">
  className?: string
  noHover?: boolean
}

export const ProjectBadge: FC<Props> = ({ organization, project, className, noHover = false }) => {
  return (
    <div className={clsx("flex gap-4 items-center", className)}>
      <ProjectImage organization={organization} width="w-12 md:w-10" height="h-12 md:h-10" project={project} />
      <div className="grid justify-center content-center gap-y-0.5">
        <div
          className={clsx(
            "mt-1 truncate leading-tight font-medium md:font-normal text-base",
            noHover ? "" : "cursor-pointer hover:underline underline-offset-1 "
          )}
        >
          {project.name}
        </div>
        {project.code && (
          <div className="flex items-center text-gray-600 text-xs">
            <BiHash />
            <div className="truncate">{project.code}</div>
          </div>
        )}
      </div>
    </div>
  )
}
