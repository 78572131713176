import { useEffect, useState } from "react"
import { getFileUploadSignedPutUrl } from "../data/api"
import { getDataUrlForImageAsync } from "../helpers/files/getDataUrlForImage"

type FileObject = {
  id: string
  file: File
  fileId: string
  objectKey: string
  preview: string
  signedUrl: string
  uploaded: boolean
}

export const useImageUpload = (
  prefix: string,
  onChange: (values: { uploaded: boolean; fileId: string; objectKey: string }[]) => void
) => {
  const [files, setFiles] = useState<File[] | undefined>()
  const [fileObjects, setFileObjects] = useState<FileObject[] | undefined>()

  useEffect(() => {
    // This prevent the handler from being called on load
    if (!files) return undefined
    onFileSelected(files)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files])

  useEffect(() => {
    if (!fileObjects) return undefined

    onChange(
      fileObjects.map(({ objectKey, uploaded, fileId }) => ({
        fileId,
        objectKey,
        uploaded,
      }))
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileObjects])

  const reset = async () => {
    setFiles(undefined)
    setFileObjects(undefined)
  }

  const onFileSelected = async (fileList: File[]) => {
    try {
      const data: FileObject[] = await Promise.all(
        fileList
          .filter((newFile) => !fileObjects?.find((currentObj) => newFile.name === currentObj.id))
          .map(async (file) => {
            const preview = await getDataUrlForImageAsync(file)
            const {
              url: signedUrl,
              fileId,
              objectKey,
            } = await getFileUploadSignedPutUrl(`${prefix}/${file.name}`, file.type)
            return {
              id: file.name,
              objectKey,
              preview,
              signedUrl,
              fileId,
              file,
              uploaded: false,
            }
          })
      )

      setFileObjects((current = []) => [...current, ...data])
    } catch (error) {
      console.error(error)
    }
  }

  const removeFileById = (id: string) => {
    setFileObjects((current = []) => current.filter((item) => item.id !== id))
  }

  const setUploadedById = (id: string, uploaded: boolean) => {
    setFileObjects((current = []) => current.map((item) => (item.id === id ? { ...item, uploaded } : item)))
  }

  return {
    setFiles,
    removeFileById,
    setUploadedById,
    fileObjects,
    reset,
  }
}
