import { Menu } from "@mui/material"
import { Dispatch, FC, ReactNode, SetStateAction, useEffect, useRef, useState } from "react"

export const CustomMenu: FC<{
  children: ReactNode
  anchor: ReactNode
  setOpen?: Dispatch<SetStateAction<boolean>>
  open?: boolean
  disabled?: boolean
  autoFocus?: boolean
}> = ({ children, anchor, setOpen = () => null, open = false, disabled = false, autoFocus = true }) => {
  const ref = useRef(null)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  useEffect(() => {
    if (open) setAnchorEl(ref.current)
  }, [open])

  return (
    <>
      <div ref={ref} onClick={(event) => !disabled && setAnchorEl(event.currentTarget)} className="cursor-pointer">
        {anchor}
      </div>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null)
          setOpen(false)
        }}
        autoFocus={autoFocus}
      >
        {children}
      </Menu>
    </>
  )
}
