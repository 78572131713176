import { TextareaAutosizeProps } from "@mui/material"
import { clsx } from "clsx"
import { useField } from "formik"
import { FC, TextareaHTMLAttributes } from "react"
import { Label } from "../deprecated"
import { Textarea } from "../deprecated/Textarea"

type Props = {
  name: string
  label?: string
  isEditing?: boolean
  labelClassName?: string
  required?: boolean
  tooltipLabel?: string
  tooltipText?: string
  className?: string
  errorContained?: boolean
  textareaClassName?: string
}

export const StandardTextarea: FC<TextareaHTMLAttributes<HTMLTextAreaElement> & Props & TextareaAutosizeProps> = ({
  name,
  label,
  isEditing = true,
  labelClassName,
  required = false,
  disabled = false,
  tooltipLabel,
  tooltipText,
  className,
  errorContained = false,
  textareaClassName,
  ...props
}) => {
  const [field, meta, helpers] = useField(name)

  const displayError = meta.touched && meta.error

  return (
    <div className={clsx("grid gap-y-2", disabled && `opacity-40`, className)}>
      {label && (
        <Label
          htmlFor={field.name}
          tooltipLabel={tooltipLabel}
          tooltipText={tooltipText}
          isRequired={required}
          className={labelClassName}
        >
          {label}
        </Label>
      )}
      <div>
        {isEditing ? (
          <Textarea
            className={clsx(displayError ? `border-red-600 border-2` : "border", textareaClassName)}
            value={field.value}
            disabled={disabled}
            onChange={(e) => {
              helpers.setValue(e.target.value)
            }}
            onBlur={() => helpers.setValue(field.value?.trim())}
            {...props}
          />
        ) : (
          <p className="w-full py-2 pl-3">{field.value}</p>
        )}
        {!errorContained &&
          (displayError ? (
            <div className="text-red-600 text-sm h-5 mt-[-7px]">{meta.error}</div>
          ) : (
            <div className="text-sm h-5 mt-[-7px]">&nbsp;</div>
          ))}
      </div>
    </div>
  )
}
