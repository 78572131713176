import { clsx } from "clsx"
import { differenceInDays, format, isAfter, isSameYear } from "date-fns"
import { FC, useMemo } from "react"
import { Maybe } from "../../graphql/generated/gql/graphql"
import { getDateProgressPercentageToNow } from "../../helpers/progress/getDateProgressPercentageToNow"
import { pluralize } from "../../helpers/strings/pluralize"
import { LabelWithNoBar } from "./LabelWithNoBar"

type DateProgressBarProps = {
  start?: Maybe<string | Date>
  end?: Maybe<string | Date>
  isComplete?: boolean
  withIcon?: boolean
}

const convertStringToDate = (date?: Maybe<string | Date>): Date | undefined => {
  if (typeof date === "string") {
    return new Date(date)
  }

  return date as Date
}

const useStartAndEndDates = (
  start?: Maybe<string | Date>,
  end?: Maybe<string | Date>
): { startDate?: Maybe<Date>; endDate?: Maybe<Date>; overDays: number } => {
  const startDate = convertStringToDate(start)
  const endDate = convertStringToDate(end)

  const overDays = endDate ? differenceInDays(new Date(), endDate) : 0

  return { startDate, endDate, overDays }
}

export const DATE_FORMAT = {
  full: "MMM dd, yyyy",
  short: "MMM dd",
}

export const formatDate = (date: Date, dateFormat: string) => {
  return format(date, dateFormat)
}

export const DateProgressBar: FC<DateProgressBarProps> = ({ start, end, isComplete }) => {
  const { startDate, endDate, overDays } = useStartAndEndDates(start, end)

  const barColor = useMemo(() => {
    if (isComplete) return "bg-green-600"

    if (overDays > 0) return "bg-orange-800"

    return "bg-gray-600"
  }, [isComplete, overDays])

  if (!startDate && !endDate) {
    return (
      <div className="flex items-center gap-4">
        <LabelWithNoBar>No set dates</LabelWithNoBar>
      </div>
    )
  }

  const currentDate = new Date()
  const percentageComplete = getDateProgressPercentageToNow(startDate || currentDate, endDate)
  const projectHasStarted = startDate ? isAfter(currentDate, startDate) : true
  const dateFormat = isSameYear(startDate || currentDate, endDate || currentDate) ? DATE_FORMAT.short : DATE_FORMAT.full

  return (
    <div className="flex items-center gap-4 text-[13px] rounded overflow-hidden">
      <div className={clsx("relative w-full h-6 rounded overflow-hidden", barColor)}>
        {projectHasStarted && overDays < 0 && (
          <div
            className={clsx(
              "absolute inset-0 rounded",
              !isComplete && "bg-gray-800",
              percentageComplete < 1 && "rounded-r-none"
            )}
            style={{
              width: `${percentageComplete * 100}%`,
            }}
          />
        )}
        <div
          className={clsx(
            "absolute inset-0 py-1 px-3 text-white flex items-center justify-end gap-4 rounded font-bold",
            "xl:justify-between"
          )}
        >
          <div className={clsx("uppercase hidden", "xl:block")}>
            {startDate ? formatDate(startDate, dateFormat) : null}
          </div>
          <div className="uppercase flex gap-1">
            <span className={clsx("hidden", "xl:block")}>{endDate ? formatDate(endDate, dateFormat) : null}</span>
            <span className="xl:hidden">{endDate ? formatDate(endDate, DATE_FORMAT.short) : null}</span>
            {overDays > 0 && !isComplete && (
              <span>
                (+{overDays} {pluralize("Day", overDays)})
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
