import { clsx } from "clsx"
import { FC, ReactNode } from "react"

type PillColor = "blue" | "gray" | "green" | "orange" | "red" | "yellow" | "violet" | "black" | "darkGray"

type Props = {
  children: ReactNode
  className?: string
  color: PillColor
}

const styles = {
  blue: "bg-blue-50 text-blue-600",
  gray: "bg-gray-100 text-gray-800",
  green: "bg-green-50 text-green-600",
  orange: "bg-orange-50 text-orange-600",
  red: "bg-red-50 text-red-600",
  yellow: "bg-yellow-50 text-yellow-600",
  violet: "bg-violet-100 text-violet-600",
  black: "bg-black text-white",
  darkGray: "bg-gray-200 text-gray-800",
}

const Pill: FC<Props> = ({ children, color, className }) => {
  return (
    <div className={clsx("inline-block rounded-3xl text-center py-1.5 px-3 leading-4", styles[color], className)}>
      {children}
    </div>
  )
}
export default Pill
