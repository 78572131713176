import { BiEdit, BiTrash } from "react-icons/bi"
import { MetadataNoteInput } from "../graphql/generated/client-types-and-hooks"
import { MetadataNote } from "../types/MetadataNote"
import { QuickMenuDotsHorizontal } from "./QuickMenu/QuickMenuDotsHorizontal"
import { MenuItem, QuickMenuMui } from "./QuickMenuMui"
import { RenderIf } from "./RenderIf"

type NoteCardProps = {
  note: MetadataNote | MetadataNoteInput
  onEdit?: (note: MetadataNote | MetadataNoteInput) => void
  onDelete?: (note: MetadataNote | MetadataNoteInput) => void
}

export const NoteCard = ({ note, onEdit, onDelete }: NoteCardProps) => {
  const canModifyCard = typeof onEdit === "function" || typeof onDelete === "function"

  const noteActions: MenuItem[][] = []

  if (onEdit) {
    noteActions.push([
      {
        value: "Edit",
        Icon: BiEdit,
        onClick: () => onEdit(note),
      },
    ])
  }

  if (onDelete) {
    noteActions.push([
      {
        value: "Delete",
        Icon: BiTrash,
        onClick: () => onDelete(note),
        color: "red",
        iconStyles: "text-red-500 size-5",
      },
    ])
  }

  return (
    <div className="ps-4 m-2 bg-yellow-100 shadow-md flex-col rounded-md break-words pr-2">
      <div className="flex justify-between items-start min-h-[20px]">
        <p className="mb-1 text-gray-600 font-bold text-xs uppercase pt-3">{note.label}</p>
        {canModifyCard && (
          <RenderIf permissionsInclude="task:update" fallbackComponent={<></>}>
            <QuickMenuMui className="" items={noteActions}>
              <QuickMenuDotsHorizontal className="text-2xl text-gray-800" />
            </QuickMenuMui>
          </RenderIf>
        )}
      </div>

      <div className="font-normal text-black min-h-[40px] pb-4">{note.content}</div>
    </div>
  )
}
