import { DatePickerProps, DatePicker as MUIDatePicker } from "@mui/x-date-pickers-pro"
import { useField, useFormikContext } from "formik"

type FormikDatePickerProps<TDate = Date> = {
  id?: string
  name: string
  placeholder?: string
  required?: boolean
  minDate?: TDate
  maxDate?: TDate
} & Omit<DatePickerProps<TDate>, "onChange" | "value">

export const DatePicker = (props: FormikDatePickerProps<Date>) => {
  const { id, format, name, placeholder, required, ...rest } = props
  const [field, { error, touched }, { setValue }] = useField(name)
  const { submitCount } = useFormikContext()

  return (
    <MUIDatePicker
      {...rest}
      format={format || "MM/dd/yyyy"}
      onChange={(val) => setValue(val)}
      minDate={props.minDate || undefined}
      maxDate={props.maxDate || undefined}
      slotProps={{
        textField: { 
          id, 
          size: "small", 
          required: required || undefined, 
          placeholder: placeholder || undefined, 
          error: !!error && touched, 
          helperText: error && submitCount > 0 ? error : undefined, 
        },
      }}
      // While using date-fns, we are choosing the lesser evils of date-fns yelling at us about passing a
      // string instead of a Date VS "uncontrolled inputs" which is a real error.
      value={field.value || null}
    />
  )
}
