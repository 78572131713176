import { Button, Chip, IconButton, Typography } from "@mui/material"
import { clsx } from "clsx"
import { FC, useContext } from "react"
import { BiTrash } from "react-icons/bi"
import { PermissionsContext } from "../providers/PermissionsProvider/PermissionsProvider"
import { CheckedPermission } from "../types/Permission"

export const SectionHeader: FC<{
  chipColor?: "primary" | "secondary" | "warning" | "success" | "error"
  chipLabel?: string
  title: string
  actionText?: string
  onClick?: () => void
  onDelete?: () => void
  permissions?: CheckedPermission | CheckedPermission[]
  deletePermissions?: CheckedPermission | CheckedPermission[]
  hideBorder?: boolean
  className?: string
  disabled?: boolean
  icon?: React.ReactNode
  size?: "small" | "large"
  showDeleteAction?: boolean
}> = ({
  chipColor = "warning",
  chipLabel,
  title,
  actionText = undefined,
  onClick,
  onDelete,
  permissions,
  deletePermissions,
  hideBorder = false,
  className,
  disabled = false,
  icon,
  size = "large",
  showDeleteAction = false,
}) => {
  const { hasPermissionTo } = useContext(PermissionsContext)

  return (
    <div
      className={clsx(
        "flex flex-wrap gap-2 justify-between items-center min-h-[40px]",
        !hideBorder && "border-b pb-2 mb-4",
        className
      )}
    >
      {icon && <div className="mr-2">{icon}</div>}
      <Typography className="my-auto" variant="h5" fontSize={size === "small" ? 20 : 24}>
        {title}
      </Typography>
      {chipLabel && <Chip label={chipLabel} color={chipColor} />}
      <div>
        {onClick && hasPermissionTo(permissions) && (
          <Button disabled={disabled} variant="text" onClick={onClick}>
            {actionText}
          </Button>
        )}
        {showDeleteAction && onDelete && hasPermissionTo(deletePermissions) && (
          <IconButton color="error" onClick={onDelete}>
            <BiTrash className="text-red-500" />
          </IconButton>
        )}
      </div>
    </div>
  )
}
