import { Checkbox, ListItemIcon, ListItemText, MenuItem, MenuList, Radio } from "@mui/material"
import { useState } from "react"
import { BiChevronDown } from "react-icons/bi"
import { ListVisibilityFilter } from "../../../../../graphql/generated/client-types-and-hooks"
import { assetListStoreActions } from "../../../../../stores/assetList"
import { CustomMenu } from "../../../../CustomMenu"

export const SingleOrMultiSelectionMenuItem = ({ selected = false, isCheckbox = false }) => (
  <ListItemIcon>{isCheckbox ? <Checkbox checked={selected} /> : <Radio checked={selected} />}</ListItemIcon>
)

type InternalVisFilter = ListVisibilityFilter | "Archived" | "Active"

type visibilityFilterOption = {
  value: InternalVisFilter
  label: string
  onSelect: () => void
}

export const AssetFilterState = (): JSX.Element => {
  const [filterMenuOpen, setFilterMenuOpen] = useState(false)
  const [stateFilter, setStateFilter] = useState<string>("Active")

  const visibilityFilterOptions: visibilityFilterOption[] = [
    {
      value: "Active",
      label: "Active",
      onSelect: () => {
        assetListStoreActions.setListFilterItems({
          isArchived: false,
          visibility: ListVisibilityFilter.All,
        })
      },
    },
    {
      value: "Archived",
      label: "Archived",
      onSelect: () => {
        assetListStoreActions.setListFilterItems({
          isArchived: true,
          visibility: ListVisibilityFilter.All,
        })
      },
    },
  ]

  return (
    <>
      <CustomMenu
        open={filterMenuOpen}
        setOpen={setFilterMenuOpen}
        anchor={
          <div className="flex gap-x-2.5 pl-4 justify-between rounded-3xl text-center p-3 leading-4 border border-gray-400 text-gray-800">
            <span className="text-gray-800">{stateFilter}</span>
            <BiChevronDown className="inline" />
          </div>
        }
        autoFocus={false}
      >
        <MenuList sx={{ minWidth: 250 }}>
          {visibilityFilterOptions.map((option) => (
            <MenuItem
              key={option.value}
              onClick={() => {
                option.onSelect()
                setStateFilter(option.value)
              }}
            >
              <SingleOrMultiSelectionMenuItem selected={stateFilter === option.value} />
              <ListItemText>{option.label}</ListItemText>
            </MenuItem>
          ))}
        </MenuList>
      </CustomMenu>
    </>
  )
}
