import { SortDirection } from "@mui/material"
import { ProjectListQuery, ProjectSortByInput } from "../../graphql/generated/client-types-and-hooks"

type KeySelector<T> = (item: T) => string | number
type SortIteratee<T> = keyof T | KeySelector<T>

// shamelessly ripped from here:
// https://www.npmjs.com/package/just-sort-by
function handleSort<T>(iteratee: SortIteratee<T>) {
  return function (a: T, b: T): number {
    const keyA = typeof iteratee === "function" ? iteratee(a) : a[iteratee as keyof T]
    const keyB = typeof iteratee === "function" ? iteratee(b) : b[iteratee as keyof T]

    if (typeof keyA === "string" && typeof keyB === "string") {
      const valueA = keyA.toUpperCase()
      const valueB = keyB.toUpperCase()

      if (valueA < valueB) {
        return -1
      }

      if (valueA > valueB) {
        return 1
      }

      return 0
    }

    if (typeof keyA === "number" && typeof keyB === "number") {
      return keyA - keyB
    }

    throw new Error("keyA and keyB must be both numbers or both strings")
  }
}

export function sortBy<T>(arr?: T[], iteratee?: SortIteratee<T>): T[] {
  if (!arr) return []
  if (!Array.isArray(arr)) {
    throw new Error("arr should be an array")
  }

  if (iteratee !== undefined && typeof iteratee !== "string" && typeof iteratee !== "function") {
    throw new Error("iteratee should be a string or a function")
  }

  if (arr.length <= 1) {
    return arr
  }

  const copied = arr.slice()

  if (!iteratee) {
    // Default sorting for homogeneous array of primitives (number, string)
    return copied.sort((a, b) => {
      if (typeof a === "number" && typeof b === "number") {
        return a - b
      }
      if (typeof a === "string" && typeof b === "string") {
        return a.localeCompare(b)
      }
      throw new Error("Non-comparable elements or mixed types")
    })
  }

  return copied.sort(handleSort(iteratee))
}

export function sortProjectOrder(
  projects?: ProjectListQuery["projects"],
  sortField?: ProjectSortByInput | null,
  sortDirection?: SortDirection | null
) {
  if (!projects) return []

  const defaultProject = projects.find((p) => p.isDefault)
  const otherProjects = projects.filter((project) => !project?.isDefault)
  const isAscending = sortDirection === "asc"
  if (sortField === ProjectSortByInput.StartDate || sortField === ProjectSortByInput.EndDate) {
    const sortByStartOrEndDate = otherProjects.sort((a, b) => {
      if (a[sortField] && b[sortField]) {
        return isAscending
          ? (a[sortField] as Date).getTime() - (b[sortField] as Date).getTime()
          : (b[sortField] as Date).getTime() - (a[sortField] as Date).getTime()
      } else if (a[sortField]) {
        return -1
      } else if (b[sortField]) {
        return 1
      } else {
        return 0
      }
    })
    return defaultProject ? [defaultProject, ...sortByStartOrEndDate] : sortByStartOrEndDate
  } else {
    const sortByName = otherProjects.sort(
      sortDirection === "desc" ? (a, b) => b.name.localeCompare(a.name) : (a, b) => a.name.localeCompare(b.name)
    )
    return defaultProject ? [defaultProject, ...sortByName] : sortByName
  }
}
