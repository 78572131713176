import { Box } from "@mui/material"
import dynamic from "next/dynamic"
import { FC } from "react"

const Interweave = dynamic(() => import("interweave").then((mod) => mod.Interweave))

type RichContentDisplayProps = {
  content: string
}

export const RichContentDisplay: FC<RichContentDisplayProps> = ({ content }) => {
  return content ? (
    <Box
      className="crewview-user-content rounded"
      sx={{
        marginTop: 2,
      }}
    >
      <Interweave content={content || null} />
    </Box>
  ) : (
    <span className="text-gray-500">None</span>
  )
}
