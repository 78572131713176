import { RefObject, useEffect } from "react"

export const useOnClickOutside = (
  refs: RefObject<HTMLElement>[],
  handler: (event: MouseEvent | TouchEvent) => void
) => {
  useEffect(() => {
    const handleEvent = (event: MouseEvent | TouchEvent) => {
      if (!(event.target instanceof Node)) return
      const hasNoCurrentRefs = refs.every((ref) => !ref.current)
      const refsContainEventTarget = refs.some((ref) => ref.current?.contains(event.target as Node))

      if (hasNoCurrentRefs || refsContainEventTarget) return

      handler(event)
    }

    const eventListener: (this: Document, ev: MouseEvent | TouchEvent) => void = (ev) => {
      handleEvent(ev)
    }

    document.addEventListener("mousedown", eventListener)
    document.addEventListener("touchstart", eventListener)

    return () => {
      document.removeEventListener("mousedown", eventListener)
      document.removeEventListener("touchstart", eventListener)
    }
  }, [refs, handler])
}
