import { Typography } from "@mui/material"
import { useField } from "formik"
import { FC, useEffect } from "react"
import { deleteFile } from "../data/api"
import { useFileUpload } from "../helpers/files/fileUpload"
import { useImageUpload } from "../hooks/useImageUpload"
import { ImageUpload } from "./ImageUpload"
import { ImageUploadPreview } from "./ImageUploadPreview"

type FileObject = { id: string; preview: string; file: File; signedUrl: string; uploaded: boolean; fileId: string }

type ImageUploadPreviewListProps = {
  disabled: boolean
  prefix: string
  name?: string
  onChange?: (files: File[]) => void
}

export const ImageUploadPreviewList: FC<ImageUploadPreviewListProps> = ({
  disabled,
  prefix,
  name = "files",
  onChange,
}) => {
  const [, { error, touched }, { setValue }] = useField(name)
  const { setFiles, fileObjects = [], removeFileById, setUploadedById } = useImageUpload(prefix, setValue)
  const displayError = (error as unknown as FileObject[])?.some((obj) => !!obj?.uploaded) && !!touched
  const errorMessage = displayError ? "Files must finish uploading" : <>&nbsp;</>

  return (
    <div>
      <ImageUpload
        multiple
        disabled={disabled}
        onFileSelected={(newFiles) => {
          setFiles(newFiles)
          if (onChange) onChange(newFiles)
        }}
        progressCounter={{
          completed: fileObjects?.filter((file) => file.uploaded).length || 0,
          total: fileObjects?.length || 0,
        }}
      >
        <>
          {fileObjects.map((fileObject) => (
            <EagerUploadImage
              key={fileObject.id}
              fileObject={fileObject}
              removeFile={removeFileById}
              setUploaded={setUploadedById}
            />
          ))}
        </>
      </ImageUpload>
      <Typography variant="caption" color="error">
        {displayError && errorMessage}
      </Typography>
    </div>
  )
}

export const EagerUploadImage: FC<{
  fileObject: FileObject
  removeFile: (id: string) => void
  setUploaded: (id: string, uploaded: boolean) => void
}> = ({ fileObject, removeFile, setUploaded }) => {
  const { uploadToS3, cancelUpload, uploadProgress, uploaded, uploadError } = useFileUpload()

  const handleDelete = async () => {
    try {
      if (uploaded) await deleteFile(fileObject.fileId)
      else await cancelUpload()
    } catch (error) {
      console.error(error)
    } finally {
      removeFile(fileObject.id)
    }
  }

  useEffect(() => {
    if (!fileObject.uploaded) uploadToS3(fileObject.signedUrl, fileObject.file)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (uploaded) setUploaded(fileObject.id, true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploaded])

  return (
    <ImageUploadPreview
      imageSrc={fileObject.preview}
      onDelete={handleDelete}
      progress={uploadProgress}
      error={!!uploadError}
    />
  )
}
