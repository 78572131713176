import { Box } from "@mui/material"
import { clsx } from "clsx"
import { FC, ReactNode } from "react"
import { overrideTailwindClasses } from "tailwind-override"

type Props = {
  labelClassName?: string
  textLabel?: ReactNode
  textLabelClassName?: string
  childrenContainerClassName?: string
  children: ReactNode
  isRequired?: boolean
  overrideStyling?: boolean
}

export const FormRow: FC<Props> = ({
  textLabel,
  children,
  textLabelClassName,
  labelClassName,
  childrenContainerClassName,
  isRequired,
  overrideStyling = false,
}) => {
  return (
    <Box
      component={textLabel ? "label" : "div"}
      className={overrideTailwindClasses(
        clsx(overrideStyling ? "" : "grid md:grid-cols-12 gap-y-1 md:gap-y-2 mb-4", labelClassName)
      )}
    >
      {textLabel && (
        <div
          className={overrideTailwindClasses(
            clsx("md:mt-2 md:col-span-5 uppercase font-bold text-xs", textLabelClassName)
          )}
          onClick={(e) => e.preventDefault()}
        >
          {textLabel}
          {textLabel && isRequired && <span className="text-gray-400"> *</span>}
        </div>
      )}
      <div className={overrideTailwindClasses(clsx("col-span-12 md:col-span-7 md:flex", childrenContainerClassName))}>
        {children}
      </div>
    </Box>
  )
}
